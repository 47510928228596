//Icon
import iconVG from '../../img/icone visions onboarding.svg';
import iconShortVG from '../../img/icons/icon-vg.svg';
import iconBO from '../../img/ICONE-BO.svg';
import iconREO from '../../img/ICONE-REO.svg';
import iconVisionsStars from '../../img/icons/visionstars.png';
import iconVisionsStarsGradient from '../../img/icons/visionstars-2.png';
import iconTimer from '../../img/ICONE-SABLIER.svg';
import iconVGOnboarding from '../../img/icone visions onboarding.svg';
import iconShield from '../../img/ICONE RGPD onboarding.svg';
import iconHuman from '../../img/ICONE Human centric onboarding.svg';
import iconUEFlag from '../../img/Flag_of_Europe.svg.png';
import iconSecurity from '../../img/Icone securite onboarding.svg';
import iconObjective from '../../img/ICONE-OBJECTIF.svg';
import iconHeart from '../../img/tinder coeur.svg';
import iconCross from '../../img/tinder croix.svg';
import iconQuotation from '../../img/quotation.png';
import iconArrow from '../../img/icons/fleche.svg';
import iconMission from '../../img/icons/ICON-MISSION.svg';
import iconCheck from '../../img/Checkbuy.svg';
import iconObjectiveAdventure from '../../img/icons/ICON-OBJECTIF-ADVENTURE.svg';
import iconInformation from '../../img/icons/icon-info.svg';
import iconOnline from '../../img/icons/ICONE-ONLINE.svg';
import iconOpenHouse from '../../img/icons/porte-ouverte-belu-vert.svg';
import iconConference from '../../img/icons/conference-bleu-vert.svg';
import iconWorkshop from '../../img/icons/atelier-bleu-vert.svg';
import iconCalendar from '../../img/icons/icon-calendrier.svg';
import iconRedirect from '../../img/icons/share-solid.svg';
import iconSetting from '../../img/PARAMETRE.svg';
import iconHome from '../../img/icone home 1.svg';
import iconMissions from '../../img/icone catalogue 1.svg';
import iconExplore from '../../img/icons/icon-explore.svg';
import iconRewards from '../../img/icons/ICONE_CADEAU_claire.svg';
import iconBilan from '../../img/icone partage 1.svg';
import iconBriefcase from '../../img/icone metiers.svg';
import iconPromoCode from '../../img/CODE PROMO BLEU.svg';
import iconGift from '../../img/icone cheque cadeau.svg';
import iconValidity from '../../img/icone validite.svg';
import iconMeeting from '../../img/rdv-conseiller.svg';
import iconTinder from '../../img/ICONE TINDER.svg';
import iconEvents from '../../img/ICONE EVENEMENT.svg';
import iconCozy from '../../img/cozy-logo.png';
import inscription from '../../img/icons/ICONE_INSCRIPTION.svg';
import laptop from '../../img/icons/ICONE_LAPTOP.svg';
import handshake from '../../img/icons/ICONE_MAIN.svg';
import ticket from '../../img/icons/icon_place.svg';
import box from '../../img/icons/icon_box.svg';
import matchAccuracy from '../../img/icons/icon_precision.svg';
import adviceOneParsoursup from '../../img/icons/conseil-1-page-parcoursup-coach.svg';
import adviceTwoParsoursup from '../../img/icons/conseil-2-page-parcoursup-coach.svg';
import adviceThreeParsoursup from '../../img/icons/conseil-3-page-parcoursup-coach.svg';
import adviceFourParsoursup from '../../img/icons/conseil-4-page-parcoursup-coach.svg';
import coach from '../../img/icons/coach-page-parcoursup-coach.svg';
import experienceProfil from '../../img/icons/profil-page-experience.svg';
import experienceBriefcase from '../../img/icons/remplir-page-experience.svg';
import experienceVisualization from '../../img/icons/VISUALISER -page-experience.svg';
import iconMatchsNavBar from '../../img/icons/icone-matchs.svg';
import landingMatchs from '../../img/icons/LP-VG-MATCHS.svg';

//Logo
import logoVisionsOnboarding from '../../img/LOGO ONBOARDING.svg';
import logoGiftRewards from '../../img/logos/LOGO-BOUTIQUE-CADEAU.svg';
import logoFile from '../../img/logos/file-circle-question-solid.svg';
import logoParcoursupCoach from '../../img/logos/logo-parcoursup-coach.jpg';

//Illus
import illustrationGrade from '../../img/illustration-note.svg';
import laptopOnboarding from '../../img/ordi onboarding.svg';
import jobOnboarding from '../../img/image-emploi.png';
import orientationOnboarding from '../../img/image-orientation.png';
import professionalizationOnboarding from '../../img/image-professionnalisation.png';
import skillsOnboarding from '../../img/image-competence.png';
import illustrationError from '../../img/Automatiser les consentements.svg';
import phoneCozy from '../../img/TELEPHONE COZY.svg';
import illustrationSwap from '../../img/illustration tinder.svg';
import progressOnboarding from '../../img/onboarding/ILLUSTRATION-ONBOARDING.png';
import resultOnboarding from '../../img/onboarding/ILLUSTRATION-ONBOARDING-2.png';
import matchsOnboarding from '../../img/onboarding/onboarding-matchs.svg';
import configAvatar from '../../img/illustrations/ILLUSTRATION-CONFIG.png';
import configObjective from '../../img/illustrations/ILLUSTRATION-CONFIG-2.png';
import configMainObjective from '../../img/illustrations/ILLUSTRATION-CONFIG-3.png';
import iconDefaultEdtech from '../../img/ICONE-EDTECHS.svg';
import fondBO from '../../img/wallpaper/FOND-BO.png';
import hookSVG from '../../img/popup crochet.svg';
import platform from '../../img/avatars/plateforme.png';
import platformCheck from '../../img/avatars/plateforme-valider.png';
import illustrationExperiences from '../../img/illustrations/ILLUSTRATION-BLOC-experience.svg';
import phoneCarouselOne from '../../img/illustrations/TEL_ECRAN_carrousel_1.png';
import phoneCarouselTwo from '../../img/illustrations/TEL_ECRAN_carrousel_2.png';
import phoneCarouselThree from '../../img/illustrations/TEL_ECRAN_carrousel_3.png';
import matchGauge from '../../img/illustrations/jauge_complete.svg';
import percentageParcoursup from '../../img/illustrations/interpretation-parge-parcoursup-coach.svg';

//Wallpaper
import wallpaperOnboarding from '../../img/onboarding/fond-onboarding.png';
import backgroundExperiences from '../../img/wallpaper/FOND-EXPERIENCE.png';
import backgroundLandingPage from '../../img/onboarding/IMG-BLOC-1-LANDING-VG-3.jpg';

// Avatar
import animal from '../../img/avatars/AVATAR-FINAL-animal.png';
import animalV2 from '../../img/avatars/AVATAR-FINAL-animal-V2.png';
import men from '../../img/avatars/AVATAR-FINAL-homme.png';
import women from '../../img/avatars/AVATAR-FINAL-femme.png';

//Tasks
import starterTools from '../../img/missionTasks/outils.svg';
import starterReward from '../../img/missionTasks/recompense.svg';
import one from '../../img/missionTasks/1.svg';
import two from '../../img/missionTasks/2.svg';
import three from '../../img/missionTasks/3.svg';
import four from '../../img/missionTasks/4.svg';
import five from '../../img/missionTasks/5.svg';
import six from '../../img/missionTasks/6.svg';
import seven from '../../img/missionTasks/7.svg';
import eight from '../../img/missionTasks/8.svg';
import nine from '../../img/missionTasks/9.svg';
import ten from '../../img/missionTasks/10.svg';
import eleven from '../../img/missionTasks/11.svg';
import twelve from '../../img/missionTasks/12.svg';
import thirtheen from '../../img/missionTasks/13.svg';
import fourteen from '../../img/missionTasks/14.svg';
import fifteen from '../../img/missionTasks/15.svg';
import sixteen from '../../img/missionTasks/16.svg';
import seventeen from '../../img/missionTasks/17.svg';
import eighteen from '../../img/missionTasks/18.svg';
import nineteen from '../../img/missionTasks/19.svg';
import twenty from '../../img/missionTasks/20.svg';
import twentyOne from '../../img/missionTasks/21.svg';
import twentyTwo from '../../img/missionTasks/22.svg';
import twentyThree from '../../img/missionTasks/23.svg';
import twentyFour from '../../img/missionTasks/24.svg';
import twentyFive from '../../img/missionTasks/25.svg';
import twentySix from '../../img/missionTasks/26.svg';
import twentySeven from '../../img/missionTasks/27.svg';
import twentyEight from '../../img/missionTasks/28.svg';
import twentyNine from '../../img/missionTasks/29.svg';
import thirty from '../../img/missionTasks/30.svg';
import thirtyOne from '../../img/missionTasks/31.svg';

// Partner
import openClassrooms from '../../img/partner/logo-OC.png';
import studi from '../../img/partner/logo-studi.png';
import aef from '../../img/partner/logo-aef.png';

// Partner Rewards
import CoqGourmet from '../../img/partnersRewards/COQ_GOURMET.png';
import CulturCity from '../../img/partnersRewards/CULTUR_CITY.png';
import HubbyInnovation from '../../img/partnersRewards/HUBBY_INNOVATION.png';

export const appImageAssets = {
    icon: {
        VG: iconVG,
        shortVG: iconShortVG,
        BO: iconBO,
        REO: iconREO,
        visionsStars: iconVisionsStars,
        visionsStarsGradient: iconVisionsStarsGradient,
        timer: iconTimer,
        VGonboarding: iconVGOnboarding,
        shield: iconShield,
        human: iconHuman,
        UEFlag: iconUEFlag,
        security: iconSecurity,
        objective: iconObjective,
        heart: iconHeart,
        cross: iconCross,
        quotation: iconQuotation,
        arrow: iconArrow,
        mission: iconMission,
        check: iconCheck,
        objectiveAdventure: iconObjectiveAdventure,
        info: iconInformation,
        online: iconOnline,
        openHouse: iconOpenHouse,
        conference: iconConference,
        workshop: iconWorkshop,
        calendar: iconCalendar,
        redirect: iconRedirect,
        setting: iconSetting,
        home: iconHome,
        missions: iconMissions,
        explore: iconExplore,
        rewards: iconRewards,
        bilan: iconBilan,
        defaultEdtech: iconDefaultEdtech,
        briefcase: iconBriefcase,
        promoCode: iconPromoCode,
        gift: iconGift,
        validity: iconValidity,
        tinder: iconTinder,
        meeting: iconMeeting,
        cozy: iconCozy,
        event: iconEvents,
        inscription,
        handshake,
        laptop,
        ticket,
        box,
        matchAccuracy,
        matchGauge,
        adviceOneParsoursup,
        adviceTwoParsoursup,
        adviceThreeParsoursup,
        adviceFourParsoursup,
        coach,
        experienceProfil,
        experienceBriefcase,
        experienceVisualization,
        matchs: iconMatchsNavBar,
        landingMatchs,
    },
    logo: {
        VGonboarding: logoVisionsOnboarding,
        gift: logoGiftRewards,
        file: logoFile,
        parcoursupCoach: logoParcoursupCoach,
    },
    illustration: {
        grade: illustrationGrade,
        laptop: laptopOnboarding,
        jobOnboarding,
        orientationOnboarding,
        professionalizationOnboarding,
        skillsOnboarding,
        error: illustrationError,
        phoneCozy,
        tinder: illustrationSwap,
        progressOnboarding,
        resultOnboarding,
        matchsOnboarding,
        configAvatar,
        configObjective,
        configMainObjective,
        hook: hookSVG,
        platform,
        platformCheck,
        experiences: illustrationExperiences,
        phoneCarouselOne,
        phoneCarouselTwo,
        phoneCarouselThree,
        percentageParcoursup,
    },
    wallpaper: {
        wallpaperOnboarding,
        BO: fondBO,
        experiences: backgroundExperiences,
        backgroundLandingPage,
    },
    avatar: {
        animal,
        animalV2,
        men,
        women,
    },
    task: {
        one,
        two,
        three,
        four,
        five,
        six,
        seven,
        eight,
        nine,
        ten,
        eleven,
        twelve,
        thirtheen,
        fourteen,
        fifteen,
        sixteen,
        seventeen,
        eighteen,
        nineteen,
        twenty,
        twentyOne,
        twentyTwo,
        twentyThree,
        twentyFour,
        twentyFive,
        twentySix,
        twentySeven,
        twentyEight,
        twentyNine,
        thirty,
        thirtyOne,
        tools: starterTools,
        reward: starterReward,
    },
    partner: {
        openClassrooms,
        studi,
        aef,
    },
    partnerRewards: {
        CoqGourmet,
        CulturCity,
        HubbyInnovation,
    },
};
