import React from 'react';

// Style & Img/Icon
import styles from './rowdatatypesapi.module.scss';

const RowDatatypesAPI = ({ datatype, checked, updateUserSelection }) => {
    if (!datatype) return null;

    return (
        <tr>
            <td className={styles.name}>
                {datatype.name.charAt(0).toUpperCase() + datatype.name.slice(1).toLowerCase()}
            </td>
            <td>
                <label htmlFor={`checkbox-${datatype._id}`} className={styles.switch}>
                    <input
                        type="checkbox"
                        name={`checkbox-${datatype._id}`}
                        id={`checkbox-${datatype._id}`}
                        onClick={(e) => updateUserSelection(datatype._id, e)}
                    />
                    <div>
                        <span className={checked ? styles.checked : styles.unChecked}></span>
                    </div>
                </label>
            </td>
        </tr>
    );
};

export const ConsentPopupDataTypeSelect = ({ data, datatype, checked, onClick, allowSelection = true }) => {
    if (!datatype) return null;

    return (
        <tr>
            <td className={styles.name} colSpan={2}>
                {datatype.name?.charAt(0).toUpperCase() + datatype.name?.slice(1).toLowerCase()} ({data?.service?.name})
            </td>
            {allowSelection ? (
                <>
                    <td>
                        <label htmlFor={`checkbox-${datatype._id}`} className={styles.switch}>
                            <input
                                type="checkbox"
                                name={`checkbox-${datatype._id}`}
                                id={`checkbox-${datatype._id}`}
                                onClick={(e) => onClick(e, datatype)}
                            />
                            <div>
                                <span className={checked ? styles.checked : styles.unChecked}></span>
                            </div>
                        </label>
                    </td>
                </>
            ) : null}
        </tr>
    );
};

export default RowDatatypesAPI;
