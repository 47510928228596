import React, { useEffect, useState } from 'react';
import styles from './MatchsPage.module.scss';
import HeaderWithNavBar from '../../../Components/Header/HeaderWithNavBar';
import GenericCard from '../../../Components/Cards/GenericCard/GenericCard';
import { appImageAssets } from '../../../data/enums/appImageAssets';
import { appLinks } from '../../../data/enums/appLinks';
import HeaderCard from '../../../Components/Header/HeaderCard/HeaderCard';
import TaskPresetIframe from '../../../Components/Cards/TasksCard/TaskPresetIframe/TaskPresetIframe';
import { useAuth } from '../../../hooks/useAuth';
import { useUserMission } from '../../../hooks/useUserMission';
import { getPresetFromAgeGroupAndSituation } from '../../../data/missions/presetMissionConfig';
import { OBJECTIVE_RESEARCH_TYPE } from '../../../data/enums/objectiveResearchType';
import { Button } from '../../../Components/Buttons/Button/Button';
import { useUserIdentifier } from '../../../hooks/useUserIdentifier';
import { serviceIds } from '../../../data/enums/services';
import TextContainer from '../../../Components/Text/TextContainer/TextContainer';
import GenericModal from '../../../Components/Modal/GenericModal/GenericModal';
import { ConsentModal } from '../../../Components/Modal/ConsentModal/ConsentModal';
import { useClient } from '../../../hooks/useClient';
import { ButtonContainer } from '../../../Components/Containers/ButtonContainer/ButtonContainer';
import { Icon } from '../../../Components/Icons/Icon/Icon';

const MatchsPage = () => {
    const { user } = useAuth();
    const { client } = useClient();

    const localConfig = localStorage.getItem('headai');
    const namespaceContext = localConfig ? JSON.parse(localConfig)?.namespace : null;

    const age_group = user?.profile?.age_group;
    const situation = user?.experiencesInfo?.situation;
    const researchType = localStorage.getItem('researchType');

    const [presetSituationType, setPresetSituationType] = useState('');
    const [consentModalVisible, setConsentModalVisible] = useState(false);
    const [consentDataLoading, setConsentDataLoading] = useState(false);

    const presetConfig = getPresetFromAgeGroupAndSituation(presetSituationType, age_group, namespaceContext);

    const { userMission } = useUserMission({ missionId: presetConfig?.missions[0] });

    const identifier = useUserIdentifier({ serviceId: serviceIds.headai })[2];

    const getIframeURL = () => {
        const ID = identifier?._id || 'test';
        const localHeadAISettings = localStorage.getItem('headai');
        const namespace = localHeadAISettings ? JSON.parse(localHeadAISettings)?.namespace || 'onisep' : 'onisep';
        const jobs_source = localHeadAISettings ? JSON.parse(localHeadAISettings)?.jobs_source || null : null;
        const jobOrCourse = situation === OBJECTIVE_RESEARCH_TYPE.job ? 'jobs' : 'courses';
        const city = user?.experiencesInfo?.city;
        let url = `https://visions.headai.com/${jobOrCourse}?user=${ID}&namespace=${namespace}`;

        if (jobs_source) url += `&jobs_source=${jobs_source}`;
        if (city) url += `&area=${city}`;

        return url;
    };

    const handleConsent = async () => {
        setConsentModalVisible(false);
        setConsentDataLoading(true);
    };

    /**
     * Handles the logic after the consents fired received responses from visionstrust
     */
    const handleDataExchangeComplete = async (responses) => {
        console.log(
            `Successful consent data exchanges: ${responses?.map((res) => res?.success)?.length}/${responses?.length}`,
        );

        // Retrieve the ID of the user for HeadAI to then use in the iframe
        const id = await client.GET('/identifiers/service/' + serviceIds.headai);

        if (id?._id) {
            setTimeout(() => {
                setConsentDataLoading(false);
            }, 3000);
        }
    };

    useEffect(() => {
        if (!user) return;

        if (situation === 'Lycéen') {
            setPresetSituationType(OBJECTIVE_RESEARCH_TYPE.student);
            return;
        }

        if (!researchType) return;

        if (researchType === 'JOB') {
            setPresetSituationType(OBJECTIVE_RESEARCH_TYPE.job);
        } else {
            setPresetSituationType(OBJECTIVE_RESEARCH_TYPE.formation);
        }
    }, [user, researchType, situation]);

    return (
        <div className={styles.page}>
            <HeaderWithNavBar tabActive={4} />

            {consentModalVisible ? (
                <GenericModal
                    open={consentModalVisible}
                    closeModal={() => {
                        setConsentModalVisible(false);
                    }}
                >
                    <ConsentModal
                        preConfiguredProviders={presetConfig.services}
                        allowSelection={true}
                        dataUserId={serviceIds.headai}
                        onConsent={handleConsent}
                        onDataExchangeComplete={handleDataExchangeComplete}
                    />
                </GenericModal>
            ) : null}

            <div className={styles.main}>
                {identifier ? (
                    <>
                        {' '}
                        <GenericCard className={styles.matchs}>
                            <HeaderCard mainTitle="Mes Matchs avec les formations"></HeaderCard>
                            <TaskPresetIframe
                                iframe={getIframeURL()}
                                taskId={userMission?.completion[0]?.task?._id}
                                userPartnerIdentifier={identifier?._id}
                                loading={consentDataLoading}
                                onRefreshResults={async () => {
                                    setConsentModalVisible(true);
                                }}
                                replay
                            ></TaskPresetIframe>
                        </GenericCard>
                        <GenericCard className={styles.cozy}>
                            <div className={styles.cozyInformation}>
                                <h2>Mes données disponibles</h2>
                                <p className={styles.description}>
                                    Retrouvez toutes vos données disponibles issues des plateformes que vous avez
                                    utilisés dans votre Cozy Cloud.
                                </p>

                                <Button navigate={appLinks.share} size="small" sx={{ color: '#fff' }}>
                                    Voir mes données
                                </Button>
                            </div>
                            <img
                                src={appImageAssets.illustration.phoneCozy}
                                alt="Illustration d'un téléphone avec le logo CozyCloud"
                            />
                        </GenericCard>
                    </>
                ) : (
                    <GenericCard className={styles.noIdentifier}>
                        <TextContainer>Vous n'avez aucun identifiant disponible</TextContainer>
                        <ButtonContainer>
                            <Button
                                startIcon={<Icon icon="arrowLeft" />}
                                size="main"
                                navigate={appLinks.missions.index}
                            >
                                Retournez sur la page "Mes missions"
                            </Button>
                        </ButtonContainer>
                    </GenericCard>
                )}
            </div>
        </div>
    );
};

export default MatchsPage;
